import '@/styles/global.scss';

import { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import enUS from 'antd/locale/en_US';
import esES from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/en';

import { router } from '@/components/navigation/Router';
import { store } from '@/redux/store';
import { useTranslation } from 'react-i18next';

const theme = {
  token: {
    colorPrimary: '#FC6634',
  },
};

const App = () => {
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    dayjs.locale(language);
  }, [language]);

  return (
    <ConfigProvider theme={theme} locale={language === 'es' ? esES : enUS}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ConfigProvider>
  );
};

export default App;
