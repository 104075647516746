import { Col, Row, Skeleton } from 'antd';

const FlatLoader = () => (
  <div className="fadeIn">
    <Row gutter={[20, 20]}>
      <Col span={24}>
        <Skeleton active className="fadeIn" />
      </Col>
      <Col span={24}>
        <Skeleton active className="fadeIn" />
      </Col>
      <Col span={24}>
        <Skeleton active className="fadeIn" />
      </Col>
    </Row>
  </div>
);

export default FlatLoader;
