import '@/i18n';

import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import HyperDX from '@hyperdx/browser';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import App from './components/App';

const container = document.getElementById('root');
const root = createRoot(container!);
const queryClient = new QueryClient();

HyperDX.init({
  apiKey: import.meta.env.VITE_APP_HYPERDX_API_KEY as string,
  service: 'rsz-admin-site',
  tracePropagationTargets: [/real-state-zihuabello.uc.r.appspot.com/i, /localhost:3030/i], // Set to link traces from frontend to backend requests
  consoleCapture: true, // Capture console logs (default false)
  advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
});

root.render(
  <QueryClientProvider client={queryClient}>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </QueryClientProvider>,
);
