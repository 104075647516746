import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Loader from '../ui/Loader';
import FlatLoader from '../ui/FlatLoader';
import PropertyFieldsScreen from '@/screens/PropertyFields';
// Screens
const Login = lazy(() => import('@/screens/Login'));
const Forgot = lazy(() => import('@/screens/Forgot'));
const Verify = lazy(() => import('@/screens/Verify'));
const DynamicCategoriesScreen = lazy(() => import('@/screens/Categories'));
const DynamicCatalogScreen = lazy(() => import('@/screens/DynamicCatalog'));

const Dashboard = lazy(() => {
  return Promise.all([
    import('@/screens/Dashboard'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const AgentsScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Agents'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const UsersScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Users'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const EmailLogsScreen = lazy(() => {
  return Promise.all([
    import('@/screens/EmailLogs'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const HomeScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Home'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const PagesScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Pages'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const LabelsScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Labels'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const ApiKeysScreen = lazy(() => {
  return Promise.all([
    import('@/screens/ApiKeys'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const RolesScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Roles'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const ConfiguationScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Configurations'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const FilesScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Files'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const CurrencyScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Currencies'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const UnitScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Units'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});
const TypeScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Types'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});
const PropertiesScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Properties'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});
const ProfileScreen = lazy(() => {
  return Promise.all([
    import('@/screens/Profile'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

// Views
const AgentDetailView = lazy(() => {
  return Promise.all([
    import('@/views/AgentDetail.view'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});
const PropertyDetailView = lazy(() => {
  return Promise.all([
    import('@/views/PropertyDetail.view'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

const PageEditView = lazy(() => {
  return Promise.all([
    import('@/views/PageEdit.view'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});
export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/forgot',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Forgot />
      </Suspense>
    ),
  },
  {
    path: '/forgot/:token',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Forgot />
      </Suspense>
    ),
  },
  {
    path: '/verify/:token',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Verify />
      </Suspense>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <Suspense fallback={<Loader />}>
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      </Suspense>
    ),
    children: [
      {
        path: '/dashboard/',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <HomeScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/pages',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <PagesScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/pages/new',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <PageEditView />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/pages/:id',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <PageEditView edit />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/users',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <UsersScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/agents',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <AgentsScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/agents/detail/:id',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <AgentDetailView />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/agents/edit/:id',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <AgentDetailView edit />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/agents/new',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <AgentDetailView edit />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/files',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <FilesScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/email-logs',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <EmailLogsScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/labels',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <LabelsScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/catalogs',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <DynamicCategoriesScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/catalog/:id',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <DynamicCatalogScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/api-keys',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <ApiKeysScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/roles',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <RolesScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/configuations',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <ConfiguationScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/currencies',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <CurrencyScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/units',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <UnitScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/types',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <TypeScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/property-fields',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <PropertyFieldsScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/properties',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <PropertiesScreen />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/properties/new',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <PropertyDetailView />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/properties/:id',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <PropertyDetailView edit />
          </Suspense>
        ),
      },
      {
        path: '/dashboard/profile',
        element: (
          <Suspense fallback={<FlatLoader />}>
            <ProfileScreen />
          </Suspense>
        ),
      },
    ],
  },
]);
