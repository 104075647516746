import { Col, Row } from 'antd';

const Loader = () => {
  return (
    <div className="loader">
      <Row align="middle" justify="center">
        <Col>
          <div className="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Loader;
