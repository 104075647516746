import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '@/hooks/auth.hook';

function ProtectedRoute({
  children,
  redirect = '/',
}: {
  children: ReactElement;
  redirect?: string;
}) {
  const { token } = useAuth();
  if (!token) {
    return <Navigate to={redirect} />;
  }
  return children;
}

export default ProtectedRoute;
